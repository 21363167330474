<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initList"
                      :delYY="true" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="章节训练"
               :showBack="false" />
      <div class="list">
        <TableLine ref="LineRef"
                   :row="row"
                   v-for="(row,index) in tableData"
                   :key="index" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getChapter2 } from '@/api/chapter.js'
export default {
  data () {
    return {
      tableData: []
    }
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
  },
  computed: {

  },
  methods: {
    async initList (id) {
      let params = {
        subject_id: id,
      }
      const { data } = await getChapter2(params)
      this.tableData = data.list.map(item => {
        item.child = item.child_data
        return item
      })
      // this.flatList(this.tableData)
    },
    flatList (list) {
      for (let index = 0; index < list.length; index++) {
        list[index].show = true
        if (list[index].child && list[index].child.length) {
          this.flatList(list[index].child)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
  }
}
</style>